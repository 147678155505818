<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">职工列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学习记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input v-model="courseName" clearable type="text" size="small" placeholder="请输入课程名称" />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel" style="font-size:14px;width:100px">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="学时学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学时学习状态:</span>
              <el-select size="small" clearable v-model="studyState" placeholder="请选择">
                <el-option
                  v-for="item in studyType"
                  :key="item.dictCode"
                  :label="item.dictValue"
                  :value="item.dictCode"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="课程名称"
                align="center"
                 show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="培训类型"
                align="center"
                 show-overflow-tooltip
                prop="trainTypeNamePath"
              />
              <el-table-column
                label="行业类型"
                align="center"
                 show-overflow-tooltip
                prop="industryNamePath"
              />
              <el-table-column
                label="岗位类型"
                align="center"
                 show-overflow-tooltip
                prop="postName"
              />
              <el-table-column
                label="培训等级"
                align="center"
                 show-overflow-tooltip
                prop="trainLevelName"
              />
              <el-table-column label="学时学习状态" align="center"  show-overflow-tooltip>
                <span slot-scope="scope">{{$setDictionary("LEARNINGSTATE", scope.row.studyState)}}</span>
              </el-table-column>
              <el-table-column
                label="最近一次学习时间"
                align="center"
                 show-overflow-tooltip
                prop="lastTime"
              />
              <el-table-column label="操作" align="center" width="200px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="doDetail(scope.row)">详情</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
export default {
  name: "studyNotes",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      studyState: "",
      trainTypeId: "",
      centerDialogVisible: false,
      studyType: [],
      datatreeList: [],
      id: "",
      props: {
        // 联级配置
        value: "id",
        label: "label",
        emitPath: false
      },
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  computed: {},
  created() {
    this.id = this.$route.query.id;
    this.getLearnType();
    this.getList();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1, id) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.courseName || null,
        studyState: this.studyState || null,
        userId: this.$route.query.id
      };
      if(this.params.trainFormId){
        params.trainTypeId = this.params.trainFormId;
      }
      if(this.params.levelFormId){
        params.trainLevelId = this.params.levelFormId;
      }
      if(this.params.occFormId){
        params.occupationId = this.params.occFormId;
      }
      if(this.params.postFormId){
        params.postId = this.params.postFormId;
      }
      if(this.params.industryFormId){
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/user/getUserLearnRecordList",
        params,
        pageNum
      });
    },
    // 获取学习状态
    getLearnType() {
      this.$post("/sys/dictionary/key", {
        dictKey: "LEARNINGSTATE"
      })
        .then(res => {
          if (res.status == "0") {
            this.studyType = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 培训类型
    getList() {
      this.$post("/sys/category/train-type/tree").then(ret => {
        if (ret.status == 0) {
          this.datatreeList = ret.data;
        }
      });
    },
    // 修改
    handleEdit(row = {}) {
      this.$router.push({
        path: "/web/addworkers",
        query: {
          row,
          stu: "edit"
        }
      });
    },
    // 新增
    add() {
      this.$router.push({
        path: "/web/addworkers",
        query: {
          stu: "add"
        }
      });
    },
    doDetail(row) {
      this.$router.push({
        path: "/web/NotesDetail",
        query: {
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
          projectId: row.projectId,
          userId: row.userId,
          isShowBtn: false
        }
      });
    }
  }
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
